import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

export function ShocalhostCard () {
  return (
      <Card>
        <CardMedia
          component="img"
          height="200"
          image="/img/shocalhost.png"
          alt="shocalhost"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Shocalhost
          </Typography>
          <Typography>Track your Movies and TV Shows</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => window.location.href = '/shocalhost'}>View</Button>
        </CardActions>
      </Card>
  )
}
